import React from 'react'

const Footer = () => {
  return (
    <>
        <footer>
            &copy; 2024 - All Rights Reserved. | Developed by Sidak Sra
        </footer>   
    </>
  )
}

export default Footer
